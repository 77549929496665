import React from 'react';

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            name: "",
            reason: "",
            message: ""
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);



    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: event.target.value });
    }

    handleSubmit(event) {
        // alert('A name was submitted: ' + this.state);
        event.preventDefault();
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="form-group"><label className="small" >Email</label><input className="form-control form-control-lg text-white form-control-dark" type="text" name="email" placeholder="Email" onChange={this.handleChange} value={this.state.email} /></div>
                <div className="form-group"><label className="small" >Your name</label><input className="form-control form-control-lg text-white form-control-dark" type="text" name="name" placeholder="Your name" onChange={this.handleChange} value={this.state.name} /></div>
                <div className="form-group"><label className="small" >What's your need?</label>
                    <div className="custom-select custom-select-lg text-white">
                        <select className="form-control-dark" type="text" placeholder="ex. System requirements" value={this.state.reason} onChange={this.handleChange} name="reason">
                            {["Web Development", 'Mobile Applications', "Design & Branding", "Database Administration", "Secure Hosting", "Project Management / Consulting", "Other"].map((r) =>
                                <option key={r}>{r}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="form-group mb-7">
                    <label className="small" >Message</label>
                    <textarea className="form-control form-control-lg form-control-dark text-white" placeholder="How can we help?" rows="4" value={this.state.message} name="message" onChange={this.handleChange}></textarea>
                </div>
                <button className="btn btn-lg btn-dd btn-block">Send</button>
            </form>

        )
    }

}

function Contact() {

   

    return (
        <div className="Contact bg-blue">
            <div className="container">
                <div className="row justify-content-center py-5 text-white">
                    <div className="col-12 col-md-9 col-lg-9 text-center mb-4">
                        <h1>Send Us A Message  </h1>
                        <p>Have a question for us? Send us a quick message using the form below and we will get back with you!</p>
                    </div>
        
                    <div className="col-12 col-md-9 col-lg-7">
                        <ContactForm></ContactForm>
                    </div>
                </div>
            </div>
        </div>



    )
}

export default Contact;