import React from 'react';

import react from './../assets/pictures/technologies/react.png';
import angular from './../assets/pictures/technologies/angular.png';
import csharp from './../assets/pictures/technologies/csharp.png';
import javascript from './../assets/pictures/technologies/javascript.png';
import css_html from './../assets/pictures/technologies/css_html.png';
import dotnet from './../assets/pictures/technologies/dotnet.png';
import mongodb from './../assets/pictures/technologies/mongodb.png';
import ionic from './../assets/pictures/technologies/ionic.png';
import sql from './../assets/pictures/technologies/sql.png';
import oracle from './../assets/pictures/technologies/oracle.png';
import express from './../assets/pictures/technologies/express.png';


function Technologies() {

    var tech = [
        react,
        angular,
        ionic,
        csharp,
        javascript,
        express,
        css_html,
        dotnet,
        mongodb,
        oracle,
        sql,
    ];

    function ListTechnologies(param) {
        const technologiesList = param.tech.map((name) =>
            <div className="col-sm-3 " key={name}>
                {/* <img className="w-100" src={imagePath('./' + name + '.png')}  /> */}
                <img className="technologies-logo w-100" src={name} />
            </div>
        );
        return (
            <div className="row justify-content-between align-items-center text-center">
            {technologiesList}
            </div>
        )

    }

    return (
        <div className="Technologies bg-grey">
            <div className="container">
            <div className="row justify-content-center text-center py-5">
                    <div className="col-12 col-md-8 col-lg-8" >
                    <h1>Results With Resolve </h1>
                        <p className="lead">We use the latest technologies in software development to help businesses grow in their respective regions and/or nationally. We provide top-of-the-line software and we are able to meet the demands of our clients, no matter how big or small.</p>
            </div>       
                </div>
                <ListTechnologies tech={tech} />

            </div>
        </div>

    )
}

export default Technologies;