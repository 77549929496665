import logo from './../assets/logos/devdemons_logo.png';
import burger from './../assets/glyphs/burger.svg';
import { Link } from 'react-scroll'
import React from 'react'
import close_icon from './../assets/glyphs/close.svg';
console.log(close_icon)

var showMenu = false;
class Navbar extends React.Component {

    constructor(props) {
        super(props); 

        this.state = {
            showMenu: false,      
            menu: ["Services", "Technologies", "Contact"],
        }
        
        this.toggleMenu = this.updateView.bind(this);
    }

    updateView(event) {
        // parent class change handler is always called with field name and value
        showMenu = !showMenu;
        this.setState({ ['showMenu']: showMenu });
    }

    render() {
        return (
            <div className="Navbar navbar-sticky">
                <div className="container">

                    <div className="navbar navbar-expand-lg justify-content-between">
                        <div className="d-lg-none"><a onClick={this.toggleMenu} className="navbar-toggler mr-1" data-id="menu-4-sidebar-toggle"><img src={burger} /></a></div>
                        <div><img src={logo} width="200" /></div>
                        <div className="collapse navbar-collapse flex-grow-0 weight-500">
                            <div className="navbar-nav">
                                {this.state.menu.map((a) =>
                                    <div className="nav-item" key={a}>
                                        <Link activeClass="active" offset={-50} className="nav-link text-dark" href="" to={a} spy={true} smooth={true}>{a}</Link>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="d-lg-none ml-auto">
                            {/* <div class="nav-item"><a class="nav-link text-dark pr-0"><img src="./assets/glyphs/search.svg" /></a></div> */}
                        </div>
                    </div>
                    {/* MOBILE */}

                    <div className={`navbar-side  ${this.state.showMenu ? "show" : ""}`} id="menu-1-sidebar" data-id="menu-1-sidebar-close">
                        <div className="navbar-side-content d-flex flex-column justify-content-between">
                            <div className="weight-700"><a onClick={this.toggleMenu} className="navbar-side-close mb-4" data-id="menu-1-sidebar-close"><img src={close_icon} width="32" /></a>
                                <div className="navbar-nav">
                                    {this.state.menu.map((a) =>
                                        <div className="nav-item" key={a}>
                                            <Link activeClass="active" offset={-50} onClick={this.toggleMenu} className="nav-link text-dark" href="" to={a} spy={true} smooth={true}>{a}</Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <MobileNav show={this.state.showMenu} menu={this.state.menu}></MobileNav> */}
                </div>


            </div>

        );
    }

}



export default Navbar;
