
import React from 'react';
import logo from './../assets/logos/devdemons_logo.png';


import f from './../assets/glyphs/facebook-small.svg';
import t from './../assets/glyphs/linkedin-small.svg';
import p from './../assets/glyphs/pinterest-small.svg';
import i from './../assets/glyphs/instagram-small.svg';

import {Link} from 'react-scroll'

function Footer() {

    const menu = ["Services", "Technologies", "Contact"]


    return (
        <div className="Footer">
            <div className='container'>
              
                <div className="row justify-content-center align-items-center py-4 ">
                <div className="col-12 col-lg-8 ">
                    <img src={logo} className="w-100"></img>
                </div>
                    <div className="col-12 col-lg-5 navbar-expand text-center weight-500">
                        <div className="navbar-nav d-block d-lg-flex justify-content-center">

                        {menu.map((a) =>
                                <div className="nav-item" key={a}> 
                                <Link to={a} offset={-50} spy={true} smooth={true}className="nav-link text-dark">{a}</Link>
                                </div>
                            )}
                            
                        </div>
                    </div>
                    <div className="col-12 col-lg-2 d-none d-lg-block text-right">
                        <a className="transparent-invert-link mr-1" href=""><img src={t} width="16" /></a>
                        <a className="transparent-invert-link mr-1" href=""><img src={f} width="16" /></a>
                        <a className="transparent-invert-link mr-1" href=""><img src={p} width="16" /></a>
                        <a className="transparent-invert-link mr-1" href=""><img src={i} width="16" /></a></div>
                    {/* <div className="col-12 d-lg-none text-center"><a className="transparent-invert-link mx-1" href=""><img src="./assets/glyphs/instagram-small.svg" width="24" /></a><a className="transparent-invert-link mx-1" href=""><img src="./assets/glyphs/facebook-small.svg" width="24" /></a><a className="transparent-invert-link mx-1" href=""><img src="./assets/glyphs/twitter-small.svg" width="24" /></a><a className="transparent-invert-link mx-1" href=""><img src="./assets/glyphs/pinterest-small.svg" width="24" /></a></div> */}
                </div>
                <div className="row pb-3">
                    <div className="col-12 text-center small"><span className="text-muted mr-1">&copy; 2019 DevDemons. All right reserved. • 330-907-1901 • North Canton, OH</span>
                    {/* <a className="d-block d-lg-inline text-muted mx-1" href=""><span>Privacy Policy</span></a><a className="d-block d-lg-inline text-muted mx-1" href=""><span>Terms of Service</span></a> */}
                    </div>
                </div>
            </div>
        </div>


    )
}





export default Footer