import React from 'react';

import web from './../assets/pictures/services/web.png';
import design from './../assets/pictures/services/design.png';
import hosting from './../assets/pictures/services/hosting.png';
import db from './../assets/pictures/services/db.png';
import mobile from './../assets/pictures/services/mobile.png';
import pm from './../assets/pictures/services/pm.png';

function Services() {
    const services = [
        { icon: web, title: 'Web Development', text: 'Developing and designing custom websites and web applications tailored to fit every client’s needs.' },
        { icon: mobile, title: 'Mobile Applications', text: 'Creating easy to use applications compatible with all major platforms (iPhone, Android, and Windows).' },
        { icon: db, title: 'Database Administration', text: 'Sustaining security, performance, and integrity of your database as well as performing migrations and conversions.' },
        { icon: design, title: 'Design & Branding', text: "Does your company not have it's own identity? Let us help you build and develop a brand for your company." },
        { icon: hosting, title: 'Secure Hosting', text: 'Our secure website hosting package is specifically calibrated for modern web technologies and delivers the best possible performance.' },
        { icon: pm, title: 'Project Management & Consulting', text: 'We create documentation, perform operational duties, and determine business priorities to save our clients time and money while developing their current projects.' }
    ]

    function ListServices(param) {
        const listServices = param.services.map((s) =>
            <div key={s.icon} className="col-12 col-md-4 mb-3"><img className=" mb-3" src={s.icon} />
                <h3>{s.title}</h3>
                <p>{s.text}</p>
                {/* <a className="weight-500 action-link" href=""><span>Read more</span></a> */}
            </div>
        );
        return (
            <div className="row py-5">
                <div className="col-12 text-center mb-4">
                    <h1>What Can We Develop For You? </h1>
                </div>
                {listServices}
            </div>
        )
    }

    return (
        <div className="Services">
            <div className="container">
                <ListServices services={services}></ListServices>
            </div>
        </div>
    )
}



export default Services;