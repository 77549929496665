import React from 'react';

import photo from './../assets/pictures/header.png';
import {Link} from 'react-scroll'


function Header() {
    return (
        <div className="Header">
        <div  className="container">
          <div className="row justify-content-between align-items-center py-5">
  <div className="col-12 col-md-5 order-2 order-md-1">
    <h1 className="display-4">Technology made simple.</h1>
    <p className="large mb-4">DevDemons is a Software Development company providing application and web development services using the latest technologies for small to medium-sized businesses across Northeast Ohio.</p>
    <div className="row align-items-center">
     <div className="col-12 col-md-5 mb-2 mb-md-0"> <Link to="Contact" offset={-50} className="btn btn-block btn-lg btn-dd" href="" spy={true} smooth={true}>Contact Us</Link></div>
      {/* <div className="col-12 col-md-7 text-center text-md-left">
        <div className="small ml-md-6">Separated they live in Books,<br />but sometimes.&nbsp;<a href=""><span>Learn more</span></a></div>
      </div> */}
    </div>
  </div>
  <div className="col-12 col-md-6 order-1 order-md-2 pl-md-0 mb-4 mb-md-0"><img className="w-100" src={photo} /></div>
</div>

</div>
        </div>


    );
}

export default Header;
